<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>

    <div class="max-w-6xl mx-auto mt-3 lg:mt-10 md:mt-5" v-if="isLoaded">
      <!-- title -->
      <div>
        <div class="font-bold text-22px text-oCharcoal">
          <i class="fas fa-bell text-22px text-oOrange"></i>
          <span class="pl-3">Event Notifications</span>
        </div>
        <p class="break-words title-description mt-1">
          Even Settings allow you to control the way you want to receive
          notifications for following events. There are 3 ways you can receive
          notifications 1. Web - Through DashBoard notification, 2. Email -
          Through your Org registered email(Will be chargeable by email
          provider) . 3. Slack - Through slack Channel.
        </p>
      </div>

      <div class="mt-3 pt-5 border-t-2">
        <div class="grid grid-cols-1 gap-4  md:grid-cols-2 lg:grid-cols-4">
          <template v-for="(item, index) in events">
            <div :key="index" class="mb-5">
              <input-label class="mb-1" :text="item.title" />
              <t-checkbox
                wrapped
                label="Email"
                :name="`EmailEnabled_${item.id}`"
                v-model="item.email_enabled"
                @change="onOff(item, 'email', $event)"
              />
              <t-checkbox
                wrapped
                label="Slack"
                :name="`SlackEnabled_${item.id}`"
                v-model="item.slack_enabled"
                @change="onOff(item, 'slack', $event)"
              />

              <t-checkbox
                wrapped
                label="Web Notification"
                :name="`WebNotiEnabled_${item.id}`"
                v-model="item.web_noti_enabled"
                @change="onOff(item, 'web', $event)"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import InputLabel from '@/components/form/InputLabel'
import { EventSettingsConfig } from '@/config/SettingsConfig'

export default {
  name: 'EventSettings',
  components: {
    InputLabel,
  },
  data() {
    return {
      isLoaded: false,
      events: {},
      // one
      illegal_move: [],
      idle_vehicle: [],
      low_speed_zone: [],
      fallen_down: [],
      // two
      critical_battery: [],
      restricted_area: [],
      iot_removed: [],
      negative_balance: [],
      // three
      pull_up: [],
      aa: null,
    }
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    getNotificationChennelOptions() {
      return [
        { value: 'EMAIL', text: 'Email' },
        { value: 'WEB', text: 'Web Notification' },
        { value: 'SLACK', text: 'Slack' },
      ]
    },
  },
  async created() {
    this.$http
      .get(EventSettingsConfig.api.index(this.orgId))
      .then((res) => {
        console.log('settings', res.data)
        // let data = res.data.data;
        // this.vehicle = data;
        this.events = res.data.data
        console.log('settingsEv', this.events)
        this.isLoaded = true
      })
      .catch((err) => {
        console.log('settignsErr', err)
      })
  },
  methods: {
    async onOff(item, key, val) {
      // console.log(item, key, val);
      let data, toastTitle, toastText, toastTextNegative, makeToastText

      data = new FormData()

      makeToastText = (channel, positive = true) => {
        let negative = '',
          onOffText = ''

        if (positive) {
          onOffText = val === true ? 'enabled' : 'disabled'
        } else {
          onOffText = val === true ? 'enable' : 'disable'
          negative = 'Failed to update '
        }

        return `${negative} ${channel} notification ${onOffText} for ${item.title} event`
      }

      toastTitle = `${item.title} Notification`

      if (key === 'email') {
        toastText = makeToastText('Email')
        toastTextNegative = makeToastText('Email', false)
        data.append('email_enabled', val)
      }

      if (key === 'slack') {
        toastText = makeToastText('Slack')
        toastTextNegative = makeToastText('Slack', false)
        data.append('slack_enabled', val)
      }

      if (key === 'web') {
        toastText = makeToastText('Web')
        toastTextNegative = makeToastText('Web', false)
        data.append('web_noti_enabled', val)
      }

      await this.$http
        .patch(EventSettingsConfig.api.update(item.id), data)
        .then((res) => {
          console.log(res)
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          return true
        })
        .catch((err) => {
          // todo: reverse toggle state
          console.log('onOffE', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: toastTitle,
              text: toastTextNegative,
            },
            3000
          )
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.title-description {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #63636c !important;
}
</style>
